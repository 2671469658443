<template>
	<div class="carhistoricalstate">
		<el-card class="box-card father-card" shadow="never">
			<!-- 头部区域 -->
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<!-- 搜索框 -->
					<el-form-item label="机构名称">
						<!-- <el-input v-model="form.carNum" placeholder="请输入车牌号码" clearable></el-input> -->
						<el-select v-model="form.companyName" placeholder="请选择机构">
							<el-option v-for="item in institutionsOptions" :key="item.uuid" :label="item.companyName"
								:value="item.uuid">
							</el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item label="分成计算方式">
						<el-select v-model="form.divideType" placeholder="请选择分成计算方式">
							<el-option v-for="item in divideTypeOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item> -->
                    <el-form-item label="订单开始结束时间">
                        <WTimer ref="WTimer" v-model="form" :startEnd="{start:'startDate',end:'endDate'}" />
                    </el-form-item>
					<!-- <el-form-item label="订单开始时间" prop="">
						<el-date-picker v-model="form.startDate" type="datetime" placeholder="开始时间" label-width="10px"
							clearable value-format="yyyy-MM-dd" format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="到" prop="">
						<el-date-picker v-model="form.endDate" type="datetime" placeholder="结束时间" clearable
							value-format="yyyy-MM-dd" format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item> -->
					<!-- 按钮区域 -->
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
						<el-button type="info" icon="el-icon-share" @click="toExcel(1)">导出</el-button>
						<!-- <el-button type="primary" icon="el-icon-plus" @click="addDialogFormVisible = true">添加</el-button> -->
					</el-form-item>
				</el-form>
			</el-row>
			<!-- 表格区域 -->
			<el-row class="tab">
				<el-table ref="multipleTable" :data="tabData" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowClass">
					<el-table-column prop="personName" label="名称" align="center"></el-table-column>
                    <el-table-column prop="personTypeName" label="人员类型" align="center"></el-table-column>
					<el-table-column prop="sumOrders" label="订单总数" align="center"></el-table-column>
					<el-table-column prop="sumFactFee" label="订单总金额" align="center"></el-table-column>
                    <el-table-column prop="sumPredictMile" label="预估总里程" align="center"></el-table-column>
                    <el-table-column prop="sumFactMile" label="实际总里程" align="center"></el-table-column>
					<el-table-column prop="sumDivideFee" label="总分成" align="center"></el-table-column>
					<!-- <el-table-column prop="sumFactFee" label="金额" align="center"></el-table-column> -->
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
							<!-- <el-button size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
              >删除</el-button>-->
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页区域 -->
				<el-pagination background @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.PageNo" :page-sizes="[10,15, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
			</el-row>
			<!-- 机构详情弹出框 -->
			<el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
				<span slot="title" class="dialog-Title">机构分成详情</span>
				<el-button type="info" icon="el-icon-share" @click="toExcel(2)">导出</el-button>
				<el-table ref="multipleTable" :data="tabData2" tooltip-effect="dark"
					style="overflow-y: scroll;width: 100%; margin-bottom: 20px;max-height: 500px; margin-top: 10px;" highlight-current-row
					stripe border :header-cell-style="rowClass">
					<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="memberName" label="订单名称" align="center"></el-table-column>
                    <!-- <el-table-column prop="personName" label="订单名称" align="center"></el-table-column> -->
					<el-table-column prop="orderDate" label="订单时间" align="center"></el-table-column>
					<el-table-column prop="sumFactFee" label="订单金额" align="center"></el-table-column>
					<el-table-column prop="startAddress" label="起始地址" align="center"></el-table-column>
					<el-table-column prop="endAddress" label="结束地址" align="center"></el-table-column>
					<el-table-column prop="sumPredictMile" label="预估总里程" align="center"></el-table-column>
					<el-table-column prop="sumFactMile" label="实际总里程" align="center"></el-table-column>
                    <el-table-column prop="sumDivideFee" label="分成" align="center"></el-table-column>
                    <el-table-column prop="remark" label="备注" align="center"></el-table-column>

				</el-table>

				<!-- 分页区域 -->
				<el-pagination style="float: right;" background @size-change="handleSizeChange1"
					@current-change="handlePageNumChange1" :current-page="paging1.PageNo" :page-sizes="[10,15, 20, 50]"
					:page-size="paging1.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total1">
				</el-pagination>

				<div slot="footer" class="dialog-footer" style="margin-top: 10px;">
					<el-button type="primary" @click="orderDialogFormVisible = false">取 消</el-button>
				</div>
			</el-dialog>
			<!-- 添加弹出框 -->
			<!-- <el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加车辆历史状态</span>
        <el-form :model="addForm" inline ref="addForm" class="addForm">
          <el-row>
            <el-form-item label="车牌号码" prop="carNum">
              <el-select v-model="addForm.carNum" placeholder="请选择状态车辆" filterable clearable>
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in CarList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select v-model="addForm.status" placeholder="请选择状态" filterable clearable>
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addForm.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>-->
		</el-card>
	</div>
</template>

<script>
import _ from 'lodash'
import store from '@/store/index.js'
import WTimer from '@/components/dateCpn/dataPiceker'

export default {
  name: 'carhistoricalstate',
  components: {
    WTimer
  },
  data () {
    return {
      tabData: [], // 表格数据源
      tabData2: [],
      form: {
        companyName: '',
        divideType: 1,
        startDate: '',
        endDate: ''
      }, // 搜索表单
      total: 0, // 总数据条数
      total1: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      paging1: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {}, // 添加表单
      // is: true,
      addFormRules: {
        companyName: [{
          required: true,
          message: '请输入机构名称',
          trigger: 'blur'
        }]
      }, // 添加表单校验规则
      selectFormRules: {},
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [{
        label: '上线',
        status: '1'
      }, {
        label: '下线',
        status: '0'
      }],
      CarList: [],
      institutionsOptions: [], // 机构列表
      divideTypeOptions: [{
        label: '里程',
        value: 1
      }, {
        label: '费用',
        value: 2
      }],
      rows: {},
      fileName: '',
      selectedWorkName: {
        companyName: ''
      },
      comName: ''
    }
  },
  created () {
    this.getInstitutions()
    // this.getAllCar()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    // 获取机构信息
    async getInstitutions () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        // "pageSize": this.paging.pageSize,
        // "pageNo": this.paging.PageNo,
        companyName: '',
        // "status": 1
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取机构列表失败')
      this.institutionsOptions = res.data
      this.form.companyName = this.institutionsOptions[0].uuid
      // this.total = +res.pageBean.pageDataCount;
      this.getAllCar()
    },
    // 获取机构分成列表
    async getAllCar () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoOrder/getCompanyDivideStats', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        companyId: this.form.companyName,
        divideType: this.form.divideType,
        startDate: this.form.startDate,
        endDate: this.form.endDate
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取失败')
      this.tabData = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 点击搜索按钮触发
    async search () {
      this.paging.PageNo = 1
      // 判断有输入账号
      if (this.form.companyName) {
        this.getAllCar()
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 切换每页显示数量时触发
    handleSizeChange1 (newSize) {
      this.paging1.pageSize = newSize
      this.getDivideStats()
    },
    // 切换页码时触发
    handlePageNumChange1 (PageNum) {
      this.paging1.PageNo = PageNum
      this.getDivideStats()
    },
    // 点击查看详情按钮触发
    async look (v) {
      this.rows = v
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
      console.log(v)
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoOrder/getPersonDivideStats', {
        pageSize: this.paging1.pageSize,
        pageNo: this.paging1.PageNo,
        personId: v.personId,
        companyId: v.companyId,
        divideType: this.form.divideType,
        endDate: this.form.endDate,
        startDate: this.form.startDate,
        personType: v.personType
      })
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      console.log(res)
      this.tabData2 = res.data
      this.total1 = +res.pageBean.pageDataCount
    },
    async getDivideStats () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoOrder/getPersonDivideStats', {
        pageSize: this.paging1.pageSize,
        pageNo: this.paging1.PageNo,
        personId: this.rows.personId,
        companyId: this.rows.companyId,
        divideType: this.form.divideType,
        endDate: this.form.endDate,
        startDate: this.form.startDate,
        personType: this.rows.personType
      })
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      console.log(res)
      this.tabData2 = res.data
      this.total1 = +res.pageBean.pageDataCount
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除${e.carNum}`, '删除车辆历史状态', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const {
            data: res
          } = await this.$http.post(
            '/pmService/tInfoVehicleStatusHistory/deleteTInfoVehicleStatusHistory', JSON
              .parse(JSON.stringify(e)))
          // 判断是否删除成功
          console.log(res)
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    async sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) {
        return this.orderDialogFormVisible = false
      } else {
        // 进行编辑操作
        const {
          data: res
        } = await this.$http.post('/pmService/tInfoVehicleStatusHistory/updateTInfoVehicleStatusHistory',
          JSON.parse(JSON.stringify(this.selectForm)))
        console.log(res)
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('修改失败')
        // 成功提示
        this.$message.success('修改成功')
        // 重新渲染数据
        // this.getAllCar()
        this.orderDialogFormVisible = false
      }
    },
    // 添加机构--确定按钮
    addSure () {
      // console.log(JSON.parse(JSON.stringify(this.addForm)));
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        console.log(JSON.parse(JSON.stringify(this.addForm)))
        // 调接口，发送添加客户请求
        const {
          data: res
        } = await this.$http.post(
          '/pmService/tInfoVehicleStatusHistory/addTInfoVehicleStatusHistory', JSON.parse(
            JSON.stringify(this.addForm)))
        console.log(res)
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
    },
    toExcel (e) {
      // if (!this.form.starOrderDate || !this.form.endOrderDate) {
      // 	return this.$message.error('请选择订单开始时间')
      // }
      // var date = new Date(this.form.startOrderDate)
      // var y = date.getFullYear()
      // var m = date.getMonth() + 1
      // m = m < 10 ? ('0' + m) : m
      // var d = date.getDate()
      // d = d < 10 ? ('0' + d) : d
      // const time = y + '-' + m + '-' + d
      // var daten = new Date(this.form.endOrderDate)
      // var yn = daten.getFullYear()
      // var mn = daten.getMonth() + 1
      // mn = mn < 10 ? ('0' + mn) : mn
      // var dn = daten.getDate()
      // dn = dn < 10 ? ('0' + dn) : dn
      // const endtime = yn + '-' + mn + '-' + dn
      // if (this.selectedWorkName.companyName) {
      // 	this.fileName = this.selectedWorkName.companyName + "订单汇表";
      // 	this.exportExcel(this.fileName + time + "到" + endtime)
      // } else if (this.selectedWorkName.companyName && this.form.startOrderDate == "" && this.form.endOrderDate ==
      // 	"") {
      // 	this.fileName = this.selectedWorkName.companyName + "订单汇表";
      // 	this.exportExcel(this.fileName)
      // } else if (this.selectedWorkName.companyName && this.form.startOrderDate && this.form.endOrderDate == "") {
      // 	this.fileName = this.selectedWorkName.companyName + "订单汇表";
      // 	this.exportExcel(this.fileName + time)
      // } else if (this.selectedWorkName.companyName && this.form.endOrderDate && this.form.startOrderDate == "") {
      // 	this.fileName = this.selectedWorkName.companyName + "订单汇表";
      // 	this.exportExcel(this.fileName + endtime)
      // } else {
      // 	this.fileName = '订单总汇表';
      // 	this.exportExcel(this.fileName)
      // }
      let url = ''
      if (e == 1) {
        url = '/pmService/tInfoOrder/companyDivideStatsToExcel'
        this.fileName = '机构分成总汇表'
      } else {
        url = '/pmService/tInfoOrder/personDivideStatsToExcel'
        this.fileName = '机构分成详情汇表'
      }
      this.exportExcel(this.fileName, url, e)
    },
    async exportExcel (value, url, e) {
      if (this.selectedWorkName.companyName) {
        this.comName = this.selectedWorkName.companyName
      } else {
        this.comName = '' // 全机构
      }
      const data = {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        companyId: this.form.companyName,
        divideType: this.form.divideType,
        personId: e == 1 ? null : this.rows.personId,
        startDate: this.form.startDate,
        endDate: this.form.endDate
      }
      console.log(data, 'data')
      // http://192.168.123.190:9000
      await this.$http.post(`${url}`,
        data, { // /userManageServer/pay/ToExcel
          responseType: 'blob'
        }).then(res => {
        console.log(res.data)
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        // 第一种
        // '订单汇表.xlsx'
        const fileName = value
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      })
    }
  }
}
</script>
<style lang="less" scoped>
	.carhistoricalstate {
		width: 100%;
		height: 100%;

		//隐藏滚动条
		::-webkit-scrollbar {
			// display: none;
			/* Chrome Safari */
		}

		.father-card {
			.btns {
				margin-bottom: 10px;
			}

			.tab {
				/deep/ .el-table {
					margin-bottom: 20px;

					.el-table__row--striped td {
						background-color: #e4eaec !important;
					}

					.current-row>td {
						background-color: #66b1ff !important;
					}
				}

				.el-pagination {
					margin-bottom: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}
			}

			.add-dialog,
			.order-dialog {
				.dialog-Title {
					color: #0097fe;
					font-size: 20px;
					font-weight: 700;
				}

				/deep/ .el-form {
					.subtitle {
						color: #0097fe;
						font-size: 14px;
						font-weight: 700;
						margin-bottom: 20px;
						padding-left: 6px;
						border-left: 5px solid #0097fe;
					}

					.el-row {
						display: flex;

						.el-form-item {
							flex: 1;
							display: flex;

							.el-form-item__content {
								flex: 1;

								.el-input {
									width: 100%;

									.el-input__inner {
										width: 100%;
									}
								}

								.el-select {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
